<template>
  <div class="container">
    <div v-if="submitted && submittedMessage" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="submitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> {{submittedMessage}}
    </div>
    <div v-if="error && errorMessage" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="error = false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{errorMessage}}
    </div>
    <div v-if="can(uiPermissions.TOOLS_ACTIONS_VIEW)"  class="row row-cards">
      <div  class="col-xl-6">
        <form
          ref="transcorp"
          data-test="card-send-orders-for-delivery"
          class="card validation"
          novalidate
          @submit.prevent="showTransportDeliveryModal = true">
          <div class="card-header">
            <h3 class="card-title">Send orders for delivery</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label
                data-test="label-delivery-date"
                class="form-label">Delivery date
              </label>
              <div class="input-group">
                <input
                  v-model="transcorpDeliveryDate"
                  type="date"
                  data-test="input-delivery-date"
                  class="form-control"
                  required>
                <div class="input-group-append">
                  <span class="input-group-text">{{moment(transcorpDeliveryDate).format('ddd')}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button
              v-if="can(uiPermissions.ORDERS_TRANSPORTER_UPDATE)"
              type="button"
              data-test="btn-update-transporter"
              class="btn btn-success position-relative mr-4"
              @click="showUploadModal = true">
              <i class="fe fe-upload-cloud"/> Update transporter
            </button>
            <button
              v-if="can(uiPermissions.ORDERS_DELIVERY_SEND)"
              type="submit"
              data-test="btn-send-orders"
              class="btn btn-primary"
              :disabled="submitting">
              Send orders for delivery to transcorp
            </button>
          </div>
        </form>
      </div>
      <div v-if="can(uiPermissions.CUSTOMERS_ADDRESSES_UPDATE)" class="col-xl-6">
        <form
          data-test="card-update-customer-address"
          class="card validation"
          novalidate
          @submit.prevent="showAddressesUploadModal = true">
          <div class="card-header">
            <h3 class="card-title">Update Addresses</h3>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              data-test="btn-update-transporter"
              class="btn btn-success position-relative">
              <i class="fe fe-upload-cloud"/> Upload Addresses
            </button>
          </div>
        </form>
      </div>
    </div>

    <confirm-modal
      :show="showUploadModal"
      :hasFileInput="true"
      :submitting="isUploadingOrders"
      confirmText="You will be changing the order transporter to hellochef. Are you sure that you want to proceed?"
      note="Only upload CSV file with 2 columns (order_id, transporter). Not following column naming convention will result in unexpected results"
      @confirm="file => uploadOrders(file)"
      @close="showUploadModal = false"
      @show="value => showUploadModal = value"></confirm-modal>

    <confirm-modal
      :show="showAddressesUploadModal"
      :hasFileInput="true"
      :submitting="isUploadingOrders"
      confirmText="Warning: Changing Customer Address Information. Proceeding will permanently alter the customer's address records. Please confirm you wish to continue."
      note="Only upload CSV file with 3 columns (email, area, development). Not following column naming convention will result in unexpected results"
      @confirm="file => uploadAddresses(file)"
      @close="showAddressesUploadModal = false"
      @show="value => showAddressesUploadModal = value"></confirm-modal>

    <confirm-modal
      :show="showTransportDeliveryModal"
      confirmText="You will be sending the orders to transcorp. Are you sure you have already uploaded the hellochef orders?"
      :isDanger="true"
      :submitting="isSendingToTranscorp"
      @close="showTransportDeliveryModal = false"
      @confirm="sendOrdersToTranscorp"
      @show="value => showTransportDeliveryModal = value"></confirm-modal>
  </div>
</template>

<script>
import {customers, orders} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';
import ConfirmModal from './modals/ConfirmModal.vue';

export default {
  components: {ConfirmModal},
  mixins: [
    submitting,
  ],
  data() {
    return {
      errorMessage: '',
      submittedMessage: '',
      transcorp: {
        deliveryDate: moment(),
      },
      showTransportDeliveryModal: false,
      isSendingToTranscorp: false,
      showUploadModal: false,
      showAddressesUploadModal: false,
      isUploadingOrders: false,
      isUploadingAddresses: false,
    };
  },
  computed: {
    transcorpDeliveryDate: {

      get() { return this.transcorp.deliveryDate.format('YYYY-MM-DD'); },
      set(newValue) { this.transcorp.deliveryDate = moment(newValue); },
    },
  },
  methods: {
    /**
     * Send orders for delivery on a specific date
     * @date 2020-10-20
     *
     */
    sendOrdersToTranscorp() {
      this.submittedMessage = '';

      // use a mixin method to validate required forms
      this.submitValidatedForm(this.$refs.transcorp, async () => {
        try {
          this.isSendingToTranscorp = true;
          // post data to transcorp with axios service
          await orders.sendToTranscorp(this.transcorp);
          this.submittedMessage = 'Orders have been sent for delivery.';
        }
        catch (ex) {
          // throw a generic message, if there are response message throw a generic error message from the response
          this.error = true;
          this.errorMessage = `Unable to send orders for delivery. ${ex.response ? ex.response.data.message : 'Response unavailable.'}`;
          throw ex;
        }
        finally {
          this.isSendingToTranscorp = false;
          this.showTransportDeliveryModal = false;
        }
      });
    },
    /**
     * Upload orders to change the transporter
     * @file csv file
     *
     */
    uploadOrders(file) {
      this.submittedMessage = '';

      this.submitIt(async () => {
        try {
          this.isUploadingOrders = true;
          await orders.uploadTransporter(file);
          this.submittedMessage = 'Orders transporter is updated.';
        }
        catch (ex) {
          // throw a generic message, if there are response message throw a generic error message from the response
          this.error = true;
          this.errorMessage = `Unable to upload orders. ${ex.response ? ex.response.data.message : 'Response unavailable.'}`;
        }
        finally {
          this.isUploadingOrders = false;
          this.showUploadModal = false;
        }
      });
    },
    uploadAddresses(file) {
      this.submittedMessage = '';

      this.submitIt(async () => {
        try {
          this.isUploadingAddresses = true;
          await customers.uploadAddresses(file);
          this.submittedMessage = 'Customer addresses have been updated.';
        }
        catch (ex) {
          // throw a generic message, if there are response message throw a generic error message from the response
          this.error = true;
          this.errorMessage = `Unable to upload addresses. ${ex.response ? ex.response.data.message : 'Response unavailable.'}`;
        }
        finally {
          this.isUploadingAddresses = false;
          this.showAddressesUploadModal = false;
        }
      });
    },
  },
};
</script>
